<template>
  <el-dialog :title="`${title}文物`" :visible.sync="visible" width="60%" v-if="visible">
    <el-menu v-if="method === 'view'" :default-active="tabIndex" mode="horizontal" @select="handleSelectTab">
      <el-menu-item index="0">文件详情</el-menu-item>
      <el-menu-item index="1" v-if="type == 'collect' || type == 'management'">版本历史</el-menu-item>
      <el-menu-item index="2" v-if="type == 'collect'">操作权限</el-menu-item>
      <el-menu-item index="3">文档元属性</el-menu-item>
    </el-menu>
    <!--文件详情-->
    <div v-if="tabIndex == 0" class="m_t1">
      <el-form class="dialogForm" size="small" :model="inputForm" :rules="dataRule" ref="inputForm" label-width="140px"
        v-loading="loading" :disabled="flag">
        <el-form-item :label="item.recordName + '：'" :prop="item.fieldEnname" v-for="(item, index) in config"
          :key="index">
          <el-input v-if="item.detailsShowType == '0' && item.fieldEnname != 'fieldName11'"
            v-model.trim="inputForm[item.fieldEnname]" :placeholder="type != 'management' ? '请输入' + item.recordName : ''"
            maxlength="20"></el-input>
          <el-input type="textarea" :rows="3" v-if="item.detailsShowType == '0' && item.fieldEnname == 'fieldName11'"
            v-model.trim="inputForm[item.fieldEnname]" :placeholder="type != 'management' ? '请输入' + item.recordName : ''"
            maxlength="500"></el-input>

          <Upload v-if="visible && item.detailsShowType == '4'" :uploadAskObj="uploadRequire" @getDataList="getDataList">
          </Upload>
          <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'fieldName8'"
            v-model="inputForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
            <el-option v-for="item in $dictUtils.getDictList('file_type')" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <!--                <el-form-item label="文物名称：" prop="culturalName" :rules="[{ required: true, message: '请输入文物名称', trigger: 'blur' }]">-->
        <!--                    <el-input v-model="inputForm.culturalName" placeholder="请输入文物名称"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="时代：" prop="times" :rules="[{ required: true, message: '请输入文物时代', trigger: 'blur' }]">-->
        <!--                    <el-input v-model="inputForm.times" placeholder="请输入文物时代"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="尺寸：" prop="size" :rules="[{ required: true, message: '请输入文物尺寸', trigger: 'blur' }]">-->
        <!--                    <el-input v-model="inputForm.size" placeholder="请输入文物尺寸"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="收藏地点：" prop="collectionLocation" :rules="[{ required: true, message: '请输入文物收藏地点', trigger: 'blur' }]">-->
        <!--                    <el-input v-model="inputForm.collectionLocation" placeholder="请输入文物收藏地点"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="上传文物图片：" prop="picture" >-->
        <!--                    <Upload v-if="visible" :uploadAskObj="uploadRequire" @getDataList="getDataList"></Upload>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="出土地点：" prop="unearthedSite">-->
        <!--                    <el-input v-model="inputForm.unearthedSite" placeholder="请输入文物出土地点"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="备注：" prop="remarks">-->
        <!--                    <el-input v-model="inputForm.remarks" placeholder="请输入文物备注"></el-input>-->
        <!--                </el-form-item>-->
      </el-form>
    </div>
    <!--版本历史-->
    <div v-if="tabIndex == 1" class="m_t1">
      <el-table :data="dataList" v-loading="loading" size="small" height="calc(100% - 130px)" class="table">
        <el-table-column type="selection" width="50" />
        <el-table-column prop="version" label="版本号" sortable>
          <template slot-scope="scope" v-if="scope.row.moveFlag == 0">
            {{ scope.row.version }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="更新人" show-overflow-tooltip />
        <el-table-column prop="createTime" label="更新时间" show-overflow-tooltip />
        <el-table-column prop="content" label="更新内容" show-overflow-tooltip width="300">
          <template slot-scope="scope">
            <span v-if="scope.row.moveFlag == 0">{{ scope.row.fileName }}</span>
            <span v-else>
              从{{ scope.row.exhibitionName }}
              【移动至】{{ scope.row.oldExhibitionName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="createIp" label="更新IP" show-overflow-tooltip />
        <el-table-column prop="mainVersion" label="是否主版本">
          <template slot-scope="scope" v-if="scope.row.moveFlag == 0">
            <el-tag :type="scope.row.mainVersion === 1 ? 'success' : 'danger'">{{ scope.row.mainVersion === 1 ? '是' :
              '否' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="restoreRow(scope.row)"
              v-if="scope.row.moveFlag == 1 && type == 'collect'">还原</el-button>
            <el-button type="text" size="mini" @click="setMain(scope.row)"
              v-if="scope.row.moveFlag == 0 && scope.row.mainVersion == 0 && type == 'collect'">设为主版本</el-button>
            <el-button type="text" size="mini" @click="downloadRow(scope.row)"
              v-if="scope.row.moveFlag == 0">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!--操作权限-->
    <div v-if="tabIndex == 2" class="m_t1">
      <el-form size="small" :model="inputForm" :rules="dataRule" ref="authorizationForm" label-width="120px"
        style="width:70%">
        <el-form-item label="操作类型：" prop="fieldName2">
          <el-select v-model="inputForm.operationType" style="width:100%" placeholder="请选择">
            <el-option v-for="item in $dictUtils.getDictList('operation_type')" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作人员：" prop="operationId" v-if="inputForm.operationType == 2">
          <el-select v-model="inputForm.operationId" style="width:100%" multiple placeholder="请选择">
            <el-option v-for="item in editorialList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!--文档元属性-->
    <div v-if="tabIndex == 3" class="m_t1">
      <el-form size="small" :model="inputForm" ref="inputForm" label-width="120px" style="width:70%" disabled>
        <el-form-item label="文件大小：" prop="fileSize">
          <el-input v-model="(inputForm.fileSize / 1024 / 1024).toFixed(2) + 'M'"></el-input>
        </el-form-item>
        <el-form-item label="文件格式：" prop="fileFormat">
          <el-input v-model="inputForm.fileFormat"></el-input>
        </el-form-item>
        <el-form-item label="更新人：" prop="updateName">
          <el-input v-model="inputForm.updateName"></el-input>
        </el-form-item>
        <el-form-item label="更新时间：" prop="updateDate">
          <el-input v-model="inputForm.updateTime"></el-input>
        </el-form-item>
        <el-form-item label="更新人IP：" prop="updateIp">
          <el-input v-model="inputForm.updateIp"></el-input>
        </el-form-item>
        <el-form-item label="创建人：" prop="createName">
          <el-input v-model="inputForm.createName"></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" prop="createDate">
          <el-input v-model="inputForm.createTime"></el-input>
        </el-form-item>
        <el-form-item label="创建人IP：" prop="createIp">
          <el-input v-model="inputForm.createIp"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-if="type == 'collect'" v-noMoreClick>保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload_zlzs"; // 导入上传组件

export default {
  components: { Upload },
  data() {
    return {
      tabIndex: "0",
      type: "",//collect：收集，management：管理，recycle：回收站
      visible: false,
      title: '',
      method: 'add',
      inputForm: {
        id: '',
        fileSize: "",
        fileFormat: "",
        fileName: "",
        createName: "",
        createIp: "",
        createTime: "",
        updateIp: "",
        updateTime: "",
        updateName: "",
        admin: "",
        exhibitionId: "",
        operationType: "0",
        operationId: [],
        url2: ""
      },
      inputForm2: {},
      //上传要求
      uploadRequire: {
        list: [],
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['png', 'bmp', 'jpg', 'jpeg', 'gif', 'PNG', 'BMP', 'JPG', 'JPEG', 'GIF'],
        megaNum: 50, // 上传文件兆数 0表示不限兆数
        showTips: true,
        showFile: true,
        uploadPath: "cultural",
        disabled: false
      },
      // 版本历史
      dataList: [],
      loading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      // // 操作权限
      // authorizationForm: {
      //     previewPersonnel: '1',
      //     editorial: ['1'],
      // },
      editorialList: [],
      config: [],
      dataRule: {
        url: [{ required: true, message: '文物图片不能为空', trigger: 'change' }],
        culturalName: [{ required: true, message: '文物名称不能为空', trigger: 'blur' }],
        times: [{ required: true, message: '时代不能为空', trigger: 'blur' }],
        size: [{ required: true, message: '尺寸不能为空', trigger: 'blur' }],
        collectionLocation: [{ required: true, message: '收藏地点不能为空', trigger: 'blur' }],
        operationId: [{ required: true, message: '操作人不能为空', trigger: 'blur' }],
        fieldName3: [{ required: true, message: '藏品号不能为空', trigger: 'blur' }],
        fieldName4: [{ required: true, message: '文物标识号不能为空', trigger: 'blur' }],
        fieldName8: [{ required: true, message: '文件类型不能为空', trigger: 'blur' }],
      },
      detailsLog: {
        operModular: "3",//模块
        operType: "14",//类型：详情更新
        operTerm: "",//内容
        operSystem: "1",//结果
      },
      fileVersionHistory: {
        fileId: "",
        primaryId: "",//移动前的所属展览id
        moveFlag: 1,//移动
        newPrimaryId: "",//移动后的所属展览id
      },
      flag: false,
      compressPicFlag: false,
      id: ''
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.method = 'add'
      }
    },
    'inputForm.operationType'(val) {
      if(val == 2) {
        this.userList(this.id)
      }
    },
  },
  methods: {
    init(method, row, exhibitionId, type) {
      this.tabIndex = '0';
      this.method = method
      this.type = type
      this.uploadRequire.list = [];
      if (this.type == "management" || this.type === "recycle") {
        this.flag = true;
        this.uploadRequire.disabled = true
      }
      if (method === 'add') {
        this.title = `新建`
        this.inputForm.id = ''
        this.inputForm.exhibitionId = exhibitionId
      } else if ((type === 'management' && method === 'view') || this.type === "recycle") {
        this.title = '查看'
        this.inputForm.id = row.id
        if (type == "collect" || type == "management") {
          this.reorganize(row.id)
        }
      } else if (method === 'view') {
        this.title = '编辑'
        this.inputForm.id = row.id
        if (type == "collect" || type == "management") {
          this.reorganize(row.id)
        }
      }

      this.querySysOriginalDescriptionAll(this.inputForm.id)
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    //详情页配置
    querySysOriginalDescriptionAll(id) {
      let that = this;
      this.$axios(this.api.catalogue.querySysOriginalDescriptionAll, {
        flag: 1,
        onDetails: 0
      }, 'get').then(data => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            that.$set(that.inputForm, v.fieldEnname, "")
          })
          // console.log("详情页配置：",this.config);
          // console.log("form：",that.inputForm);
          this.visible = true;
          // this.$nextTick(() => {
          // this.$refs.inputForm.resetFields()
          // })
          if (this.method === 'view') {
            this.fileInfo(id);
          }
        }
      })
    },
    fileInfo(id) {
      this.id = id
      //文件信息
      this.$axios(this.api.common.queryById, {
        'id': id,
        'flag': 1,
        'onDetails': 0
      }, 'get').then(data => {
        if (data.status) {
          // this.title = data.data.fileName;
          this.inputForm = this.recover(this.inputForm, data.data)
          console.log(this.inputForm);
          // this.inputForm.url=this.$getUrl()+this.inputForm.url
          this.inputForm.url2 = this.inputForm.url
          // console.log(data.data);
          // console.log(this.inputForm);
          this.uploadRequire.list.push({
            name: this.inputForm.fileName,
            url: this.inputForm.url2
          })
          this.userList(id);//所有用户
          //原来的文件对象，用于判断是否修改，记录日志
          // this.inputForm2=JSON.parse(JSON.stringify(this.inputForm))
        }
      })
    },
    userList(id) {
      this.$axios(this.api.auth.sysuserQueryAllList, {
        'current': 1,
        'size': 999,
        orderBy: '',
        name: '',
        loginName: '',
      }, 'get').then(data => {
        if (data && data.status) {
          this.editorialList = data.data.records
          this.permissionsUser(id);
        }
      })
    },
    permissionsUser(id) {
      let that = this;
      that.inputForm.operationId = []
      // console.log(that.fileDetailsForm.operationId);
      // that.operationId = []
      that.$axios(that.api.common.authorityList, { fileId: id }, 'get').then(data => {
        if (data && data.status) {
          data.data.forEach(function (v) {
            if (v.userId) {
              that.inputForm.operationId.push(v.userId)
            }
            // that.fileDetailsForm.operationId.push(v.userId)
          })
          // this.$set(that.fileDetailsForm,'operationId',that.operationId)
          // that.fileDetailsForm.operationId = that.operationId
        }
        console.log(that.inputForm);
        this.inputForm2 = JSON.parse(JSON.stringify(this.inputForm))
      })
      // console.log(that.fileDetailsForm.operationId);
    },
    // tab切换
    handleSelectTab(index) {
      this.tabIndex = index;
    },
    reorganize(id) {
      this.$axios(this.api.common.culturalVersionHistoryList, {
        "fileId": id,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data.status) {
          this.dataList = data.data.records;
          this.total = parseInt(data.data.total)
          // console.log(this.dataList);
        }
      })
    },
    // 获取上传数据
    getDataList(data) {
      console.log("123123", data);
      if (data.state == 0) {
        this.inputForm.url = data.data[0].url
        this.inputForm.fileName = data.data[0].name
        this.inputForm.fileSize = data.data[0].size
        this.inputForm.fileFormat = data.data[0].type
        this.$refs.inputForm.clearValidate('url');
      }
      if (data.state == 1) {
        this.compressPicFlag = true;
      }
      if (data.length == 0) {
        this.inputForm.fileName = ''
        this.inputForm.url = ''
        this.inputForm.fileSize = ''
        this.inputForm.fileFormat = ''
      }
    },
    // 还原
    restoreRow(row) {
      let fileCollect = {
        id: row.fileId,
        exhibitionId: row.primaryId,
      }
      console.log(fileCollect);
      this.detailsLog.operType = '2'
      this.$axios(this.api.common.culturalRelicsVersionReduction, fileCollect, 'put').then(data => {
        if (data.status) {
          this.detailsLog.operSystem = '0'
          // this.$message.success(data.msg)
          this.$message.success("还原成功")
          this.$emit('refreshDataList')
          console.log(row);
          this.batchInsert(row);
          this.visible = false
        } else {
          this.detailsLog.operSystem = '1'
        }
        row.fileName = this.inputForm.culturalName
        // this.logSave2(row);
      })
    },
    batchInsert(row) {
      console.log(row);
      let that = this;
      let batchList = []
      that.fileVersionHistory.fileId = row.fileId
      that.fileVersionHistory.primaryId = this.inputForm.exhibitionId
      that.fileVersionHistory.newPrimaryId = row.primaryId
      batchList.push(that.fileVersionHistory)
      // console.log(batchList);
      this.$axios(this.api.common.culturalVersionHistoryBatchInsert, batchList, 'post').then(data => {
        // console.log("日志：",data);
        // if(data.status){
        // this.getFileList()
        // }
      })
    },
    //设置主版本
    setMain(row) {
      let fileCollect = {
        id: row.id,
        mainVersion: 1,
        fileId: this.inputForm.id,
      }
      this.$axios(this.api.common.culturalVersionHistoryUpdateById, fileCollect, 'put').then(data => {
        this.detailsLog.operType = '8'
        if (data.status) {
          this.detailsLog.operSystem = '0'
          this.$message.success(data.msg)
          this.$emit('refreshDataList')
          this.visible = false
        } else {
          this.detailsLog.operSystem = '1'
        }
        // this.logSave2(row);
      })
    },
    // 下载
    downloadRow(row) {
      let log = {
        operModular: "3",//模块
        operType: "19",//类型
        operTerm: row.fileName,//内容
        operSystem: "1",//结果:失败
      }
      if (this.type == "management") {
        log.operModular = "4"
      }
      console.log(row);
      const a = document.createElement('a')
      //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
      const url = row.url;  // 完整的url则直接使用
      // 这里是将url转成blob地址，
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        a.href = URL.createObjectURL(blob)
        // console.log(a.href)
        a.download = row.fileName // 下载文件的名字
        // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
        document.body.appendChild(a)
        a.click()
        log.operSystem = "0"
        // this.$axios(this.api.common.logSave, log, 'post')
      })
    },
    //新增日志
    logSave2(row) {
      if (row) {
        // console.log("删除");
        this.detailsLog.operTerm = row.fileName
        console.log(this.detailsLog);
        this.$axios(this.api.common.logSave, this.detailsLog, 'post').then(data => {
          // console.log("日志：",data);
          // if(data.status){
          // this.getFileList()
          // }
        })
      }
    },
    save() {
      if (!this.inputForm.url) {
        if (this.compressPicFlag) {
          this.$message('图片压缩中，请勿操作');
          return;
        } else {
          this.$message('请上传文物图片');
          return;
        }
      }
      if (!this.$publicUtile.isEqual(this.inputForm, this.inputForm2)) {
        this.loading = true
        let api = '', type = '';
        if (this.method === 'add') {  // 新增
          api = this.api.common.culturalRelicsSave;
          type = 'post';
        } else if (this.method === 'view') {  // 修改
          api = this.api.common.culturalRelicsUpdateById
          type = 'put';
        }
        let detailsLog = {
          operModular: "3",//模块
          operType: "20",//类型：详情更新
          operTerm: this.inputForm.culturalName,//内容
          operSystem: "1",//结果
        }
        this.$axios(api, this.inputForm, type).then(data => {
          this.compressPicFlag = false
          this.loading = false
          let log = [];
          if (data && data.status) {
            detailsLog.operSystem = "0"
            if (this.method === 'view') {
              this.detailsLog.operSystem = '0'
              let update = []
              for (let key of Object.keys(this.inputForm2)) {
                let mealName = this.inputForm2[key];
                for (let key2 of Object.keys(this.inputForm)) {
                  let mealName2 = this.inputForm[key2];
                  if (key === key2 && mealName !== mealName2) {
                    let obj = {
                      "key": key,
                      "old": mealName,
                      "new": mealName2,
                    }
                    update.push(obj)
                  }
                }
              }
              this.config.forEach(function (v) {
                update.forEach(function (f) {
                  if (v.fieldEnname == f.key) {
                    // console.log("更新值：",v.recordName+"从"+f.old+"更新为"+f.new);
                    log.push(v.recordName + "从" + f.old + "更新为" + f.new)
                  }
                })
              })
            }
            this.$message.success(data.msg)
            this.visible = false
            this.$emit('refreshDataList')
          } else {
            detailsLog.operSystem = "1"
            this.$message.error(data.msg)
          }
        })
      } else {
        this.visible = false
      }
    },
    // 保存
    doSubmit() {
      // console.log(this.tabIndex);
      if (this.tabIndex == '0') {
        this.$refs['inputForm'].validate((valid) => {
          if (valid) {
            this.save()
          }
        })

      } else if (this.tabIndex == '2') {
        this.$refs['authorizationForm'].validate((valid) => {
          if (valid) {
            this.save()
          }
        })
      } else {
        this.save()
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.reorganize(this.inputForm.id);
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.reorganize(this.inputForm.id);
    },
  }
}
</script>

<style scoped></style>
