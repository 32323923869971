<template>
  <!--图片上传组件-->
  <div>
    <el-upload class="upload-demo" :accept="uploadRequire.accept" :action="uploadUrl" :headers="myHeaders"
      :on-progress="handleProgress" :on-remove="handleRemove" :show-file-list="uploadRequire.showFile"
      :file-list="uploadRequire.list" list-type="picture" :on-success="handleAvatarSuccess" :on-exceed="exceedFile"
      :limit="uploadRequire.count" :beforeUpload="beforeAvatarUpload" :disabled="uploadRequire.disabled">
      <el-button size="small" type="primary" :disabled="uploadRequire.disabled">{{ uploadRequire.btnName }}</el-button>
      <div slot="tip" class="el-upload__tip" v-if="uploadRequire.showTips">文件大小 ≤
        {{ uploadRequire.megaNum }}M，支持格式：{{ `${uploadRequire.fileType.join('、')}` }}</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    uploadAskObj: Object,
  },
  data() {
    return {
      // 上传
      myHeaders: { Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token') },
      uploadRequire: { //上传文件要求
        count: 0, // 上传文件数量 0表示不限制文件的数量
        size: '200*200', // 图片尺寸
        list: [], // 已文件的图片
        megaNum: 0, // 上传文件兆数 0表示不限兆数
        showFile: false, // 是否显示已上传文件列表
        showTips: false, // 是否显示上传提示
        btnName: '选择文件', // 按钮名称
        fileType: ['jpg', 'png', 'gif', 'mp4', 'PDF', 'pdf', 'DOC', 'doc'], // 上传文件类型
        accept: "",
        uploadPath: "",
        disabled: false,
      },
      uploadUrl: "",
      ListArr: [],
    }
  },
  watch: {
    uploadAskObj: {
      handler() {
        this.matching()
      },
      deep: true,
    },
  },
  created() {
    this.matching()
  },
  methods: {
    // 匹配上传要求
    matching() {
      Object.keys(this.uploadAskObj).map((key1) => {
        Object.keys(this.uploadRequire).map((key2) => {
          if (key1 == key2) {
            this.uploadRequire[key2] = this.uploadAskObj[key1]
          }
        })
      })
      this.uploadUrl = this.$globalUrl() + 'zyd-common/file/exhibitionUpload?uploadPath=' + this.uploadRequire.uploadPath
    },
    // 文件上传中
    handleProgress(event, file) {
      this.$emit('getDataList', { data: [{ name: file.name, url: '' }], state: 1 });
    },
    // 移除上传的文件
    handleRemove(file, list) {
      this.ListArr = [];
      for (let i = 0; i < list.length; i++) {
        this.ListArr.push(list[i].response.data)
      }
      this.$emit('getDataList', this.ListArr);
    },
    // 文件上传成功时的钩子
    handleAvatarSuccess(response, file) {
      console.log(response);
      console.log(file);
      if (response.status == true) {
        this.ListArr.push({
          name: file.name.substring(0, file.name.lastIndexOf(".")),
          url: file.response.data,
          size: file.size,
          type: file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length)
        })
        this.$emit('getDataList',
          {
            data: [{
              name: file.name.substring(0, file.name.lastIndexOf(".")),
              url: file.response.data,
              actualPath: file.response.data,
              size: file.size,
              type: file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length),

            }], state: 0
          });
      } else { }
    },

    // 文件超出个数限制时的钩子
    exceedFile(files, list) {
      if (this.uploadRequire.count > 0) {
        this.$message.error("只能选择" + this.uploadRequire.count + "张图片");
      }
    },

    // 限制文件大小跟格式
    beforeAvatarUpload(file) {
      let that = this;
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      let testType = Boolean(that.uploadRequire.fileType.find(item => item == testmsg) == testmsg)
      if (!testType && this.uploadRequire.accept != '*') {
        that.$message.error(`上传文件只能是${that.uploadRequire.fileType.join('、')}格式!`);
        return false
      }
      const isLt2M = file.size / 1024 / 1024 <= this.uploadRequire.megaNum
      if (!isLt2M && this.uploadRequire.megaNum != 0) {
        this.$message.error("上传文件大小不能超过 " + this.uploadRequire.megaNum + "MB!");
        return false
      }
    },
  }
}
</script>

<style scoped></style>
